// extracted by mini-css-extract-plugin
export var additionalServiceAmountSelection = "additional-services-module--additional-service-amount-selection--719c4";
export var additionalServiceColHeader = "additional-services-module--additional-service-col-header--ddd27";
export var additionalServiceItem = "additional-services-module--additional-service-item--ca4e9";
export var additionalServiceItemDescription = "additional-services-module--additional-service-item-description--d6309";
export var additionalServiceItemName = "additional-services-module--additional-service-item-name--52512";
export var additionalServices = "additional-services-module--additional-services--b85ae";
export var additionalServicesList = "additional-services-module--additional-services-list--5014f";
export var buttonWrapperTwoButtons = "additional-services-module--button-wrapper-two-buttons--68c22";
export var card = "additional-services-module--card--232f7";
export var closeButton = "additional-services-module--close-button--687a3";
export var description = "additional-services-module--description--a0a0c";
export var descriptionCopytext = "additional-services-module--description-copytext--03656";
export var dr = "additional-services-module--dr--57843";
export var header = "additional-services-module--header--7f1de";
export var hs = "additional-services-module--hs--b1221";
export var inner = "additional-services-module--inner--2238e";
export var modalContent = "additional-services-module--modal-content--fef49";
export var primary = "additional-services-module--primary--07894";
export var productAndServiceName = "additional-services-module--product-and-service-name--935f9";