import React, { FC, useContext } from 'react';
import FocusTrap from 'react-focus-trap';
import * as ModalStyles from '../../../_scss/modules/_modal.module.scss';

import HsButton from '../../_common/hs-button/hs-button';
import { useShoppingCartDetails } from './shopping-cart-context';
import { defaultOrder, OrderContext } from '../../../context/order/order.context';
import {
    useShoppingCartStoryBlok,
    useShoppingCartStoryBlokCommon,
    useFlaggedShoppingCartArticle,
    useShoppingCartEvents,
} from './shopping-cart-context';
import { navigate } from 'gatsby';
import { ManufacturerStoryblok } from '../../../_shared/interfaces/storyblok';
import { trackEvent } from '../../../lib/track/track';
import { useOrderStep } from '../../../context/order/order-step-context';

const OrderShoppingCartConfirmation: FC = () => {
    const storyblokCommon = useShoppingCartStoryBlokCommon();
    const blok = useShoppingCartStoryBlok();
    const { items = [] } = useShoppingCartDetails();
    const flaggedArticleIndex = useFlaggedShoppingCartArticle();
    const { setFlaggedArticle } = useShoppingCartEvents();
    const {
        order: { articles = [] },
        updateOrder,
    } = useContext(OrderContext);

    const isLastArticle = items.length === 1;
    const deleteArticle = () => (isLastArticle ? removeLastArticle() : removeArticleFromList());
    const hidden = flaggedArticleIndex < 0;
    const step = useOrderStep();

    const removeLastArticle = () => {
        trackProductRemove(articles[0]?.product);

        updateOrder({
            ...defaultOrder(),
            manufacturer: storyblokCommon?.workshopServices?.content?.manufacturer?.[0]?.name,
        });
        navigate(location.pathname, { state: { step: 'services' } });
        setFlaggedArticle(-1);
    };

    const removeArticleFromList = () => {
        trackProductRemove(articles[flaggedArticleIndex]?.product);
        updateOrder({
            articles: [...articles.slice(0, flaggedArticleIndex), ...articles.slice(flaggedArticleIndex + 1)],
        });
        setFlaggedArticle(-1);
    };

    const trackProductRemove = (product: string | undefined) => {
        let stepName = '1:ServiceSelection';
        switch (step) {
            case 'delivery':
                stepName = '2:Shipment';
                break;
            case 'payment':
                stepName = '3:Payment Data';
                break;
            case 'overview':
                stepName = '4:Check&Order';
                break;
        }
        if (product) {
            trackEvent('serviceorder.productRemove', {
                serviceorder: { type: 'workshop_service', name: stepName },
                serviceorderField: { name: 'ShoppingCart', value: { product } },
            });
        }
    };
    return (
        <FocusTrap className={hidden ? ModalStyles.hidden : ''}>
            <div className={ModalStyles.modal}>
                <div className={ModalStyles.inner}>
                    <div className={ModalStyles.content}>
                        <HsButton
                            appearance="secondary"
                            icon={'close'}
                            onClick={() => setFlaggedArticle(-1)}
                            size="small"
                            className={ModalStyles.close}
                            storyblokCommon={storyblokCommon}
                        />
                        <h4>{blok.dialogue_headline}</h4>
                        <div style={{ marginBottom: '24px' }}>
                            <p className={!isLastArticle ? ModalStyles.hidden : ''} style={{ paddingBottom: '8px' }}>
                                {blok.dialogue_copytext_last_product}
                            </p>
                            <p>{blok.dialogue_copytext}</p>
                        </div>

                        <div>
                            <HsButton appearance={'secondary'} onClick={() => setFlaggedArticle(-1)} storyblokCommon={storyblokCommon}>
                                {blok.dialogue_cancel}
                            </HsButton>
                            <div style={{ display: 'inline-block', marginRight: '16px' }} />
                            <HsButton appearance={'primary'} onClick={deleteArticle} storyblokCommon={storyblokCommon}>
                                {blok.dialogue_delete}
                            </HsButton>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default OrderShoppingCartConfirmation;
