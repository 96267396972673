import React from 'react';
import { IOrder } from '../../_shared/interfaces/order';
import { deliveryErrorCheck, paymentErrorCheck, overviewErrorCheck } from '../../lib/helpers/helpers';

const freeze = Object.freeze;
export interface IOrderContext {
    order: IOrder;
    stepStates: StepStates;
    updateOrder: (order: IOrder) => void;
    resetOrder: () => Promise<void>;
    isRestored: boolean;
}

export enum StepState {
    Open,
    Done,
}

export interface StepStates {
    service: StepState;
    delivery: StepState;
    payment: StepState;
    overview: StepState;
}

export const defaultOrder: () => IOrder = () => ({
    manufacturer: '',
    articles: [
        {
            product: '',
            serialNumber: '',
            service: '',
            serviceIntervalMonths: 0,
            notes: '',
            amount: 1,
        },
    ],
    additionalCosts: '0',
    deliveryFromCustomer: {
        type: 'self-organized',
        price: 0,
    },
    deliveryToCustomer: {},
});

const defaultStepStates = { service: StepState.Open, delivery: StepState.Open, payment: StepState.Open, overview: StepState.Open };

export const OrderContext = React.createContext<IOrderContext>({
    order: { ...defaultOrder() },
    stepStates: { ...defaultStepStates },
    isRestored: false,
    updateOrder: () => {},
    resetOrder: async () => {},
});

class OrderContextProvider extends React.Component<{}, { order: IOrder; stepStates: StepStates; isRestored: boolean }> {
    constructor(props: Readonly<IOrderContext>) {
        super(props);
        this.state = {
            order: { ...defaultOrder() },
            stepStates: { ...defaultStepStates },
            isRestored: false,
        };
    }

    public componentDidMount(): void {
        this.restoreData();
    }

    public render() {
        return (
            <OrderContext.Provider
                value={{
                    ...this.state,
                    updateOrder: this.updateOrder,
                    resetOrder: this.resetOrder,
                }}
            >
                {this.props.children}
            </OrderContext.Provider>
        );
    }

    public updateOrder = (order: IOrder): void => {
        const updatedOrder = { ...this.state.order, ...order };

        // delete all articles, etc on manufacturer change
        if (
            this.state.order.manufacturer &&
            this.state.order.articles &&
            order.manufacturer &&
            order.manufacturer !== this.state.order.manufacturer
        ) {
            updatedOrder.additionalCosts = '0';
            updatedOrder.deliveryToCustomer = { ...order.deliveryToCustomer, price: 0 };
            updatedOrder.deliveryFromCustomer = { ...defaultOrder().deliveryFromCustomer };
            if (order.articles?.length) {
                updatedOrder.articles = order.articles;
            }
        }

        // set default article if no article exist
        if (updatedOrder.articles?.length === 0) {
            updatedOrder.articles = [
                {
                    product: '',
                    serialNumber: '',
                    service: '',
                    serviceIntervalMonths: 0,
                    notes: '',
                    additionalServices: [],
                    amount: 1,
                },
            ];
        }

        this.setState(
            () => ({
                order: updatedOrder,
            }),
            () => {
                this.storeData('order', this.state.order);
                this.updateStepStates();
            },
        );
    };

    public resetOrder = (): Promise<void> => {
        return new Promise((resolve) => {
            this.setState(
                {
                    order: { ...defaultOrder() },
                    stepStates: { ...defaultStepStates },
                },
                () => {
                    localStorage.removeItem('order');
                    resolve();
                },
            );
        });
    };

    private restoreData = (): void => {
        const storedOrder = this.getStoredData('order');
        this.setState(
            {
                order: storedOrder ? storedOrder : defaultOrder(),
                isRestored: false,
            },
            () => {
                this.updateStepStates();
                this.setState({ isRestored: true });
            },
        );
    };

    private storeData = (id: string, data: any): void => {
        localStorage.setItem(id, JSON.stringify(data));
    };

    private getStoredData = (id: string): any => {
        const data = localStorage.getItem(id);
        return data ? JSON.parse(data) : undefined;
    };

    private updateStepStates = () => {
        const stepStates = { ...defaultStepStates };

        if (
            this.state.order.manufacturer &&
            this.state.order.articles?.length &&
            this.state.order.articles[0].product &&
            this.state.order.articles[0].serialNumber &&
            this.state.order.articles[0].service
        ) {
            stepStates.service = StepState.Done;
        }

        const deliveryErrors = deliveryErrorCheck(this.state.order);
        if (!deliveryErrors.deliveryFromError && !deliveryErrors.deliveryToError) {
            stepStates.delivery = StepState.Done;
        } else {
            stepStates.delivery = StepState.Open;
        }

        const paymentErrors = paymentErrorCheck(this.state.order);
        stepStates.payment = paymentErrors ? StepState.Open : StepState.Done;

        const overviewErrors = overviewErrorCheck(this.state.order, stepStates, process.env.GATSBY_TAX_FIELD === 'true');
        stepStates.overview = overviewErrors ? StepState.Open : StepState.Done;

        this.setState({ stepStates });
    };
}

export default OrderContextProvider;
