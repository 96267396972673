import OktaAuth, { OktaAuthOptions } from '@okta/okta-auth-js';

export const oktaConfig: OktaAuthOptions = {
    issuer: `${process.env.GATSBY_HS_OKTA_ISSUER}`,
    clientId: `${process.env.GATSBY_HS_OKTA_CLIENT_ID}`,
    redirectUri: typeof window !== 'undefined' ? window.self.location.origin : `${process.env.GATSBY_SITE_URL}`,
    postLogoutRedirectUri: typeof window !== 'undefined' ? window.self.location.origin : `${process.env.GATSBY_SITE_URL}`,
    tokenUrl: `${process.env.GATSBY_HS_OKTA_ISSUER}/v1/token`,
    authorizeUrl: `${process.env.GATSBY_HS_OKTA_ISSUER}/v1/authorize`,
    pkce: true,
};

export const oktaAuth = new OktaAuth(oktaConfig);
