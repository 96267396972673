import React, { FC } from 'react';
import FocusTrap from 'react-focus-trap';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AdditionalService } from '../../../_shared/interfaces/order';
import AdditionalServicesCard from './additional-services-container';
import { useAdditionalServices } from './additional-services-context';
import Modal from './modal';
import VisibilityToggle from './visibility-toggle';
import { OrderServicesStoryblok } from '../../../_shared/interfaces/storyblok';

type Props = {
    blokServices: OrderServicesStoryblok;
    storyblokCommon: StoryblokCommon;
};

const AdditionalServices: FC<Props> = ({ blokServices, storyblokCommon }) => {
    const {
        state: { visible },
    } = useAdditionalServices();
    return (
        <VisibilityToggle isVisble={visible}>
            <FocusTrap>
                <Modal>
                    <AdditionalServicesCard blokServices={blokServices} storyblokCommon={storyblokCommon} />
                </Modal>
            </FocusTrap>
        </VisibilityToggle>
    );
};

export default AdditionalServices;
