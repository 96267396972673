import React from 'react';
import { useIntl } from 'react-intl';
import { OrderContext } from '../../../context/order/order.context';
import { AccountContext } from '../../../context/account/account.context';
import HsLink from '../../_common/hs-link/hs-link';
import OverviewAddress from './address/address';
import OverviewEntry from './entry/entry';
import { isAddressValid, defaultAddress, isEmailValid } from '../../../lib/helpers/helpers';
import { IOrderCustomer } from '../../../_shared/interfaces/order-customer';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import ContactForm from '../../_common/contact-form/contact-form';
import { IContact } from '../../../_shared/interfaces/contact';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import {
    OrderDeliveryFromCustomerStoryblok,
    OrderDeliveryToCustomerStoryblok,
    OrderOverviewStoryblok,
    OrderPaymentStoryblok,
} from '../../../_shared/interfaces/storyblok';
import { navigate } from 'gatsby';

import * as OrderOverviewStylesHs from './overview.hs.module.scss';
import * as OrderOverviewStylesDr from './overview.dr.module.scss';

import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import AddressListPicker from '../../_common/address-list-picker/address-list-picker';
import HsButton from '../../_common/hs-button/hs-button';
import AddressForm from '../../_common/address-form/address-form';
import { IAddress } from '../../../_shared/interfaces/address';
import HsFileUpload from '../../_common/hs-file-upload/hs-file-upload';

const OrderOverview = ({
    blok,
    blokFrom,
    blokTo,
    blokPayment,
    showErrors,
    setExternalHasErrors,
    storyblokCommon,
}: {
    blok: OrderOverviewStoryblok;
    blokFrom: OrderDeliveryFromCustomerStoryblok;
    blokTo: OrderDeliveryToCustomerStoryblok;
    blokPayment: OrderPaymentStoryblok;
    showErrors: boolean;
    setExternalHasErrors?: (b: boolean) => void;
    storyblokCommon: StoryblokCommon;
}) => {
    const intl = useIntl();
    const OrderOverviewStyles = storyblokCommon.configuration.content.theme === 'dr' ? OrderOverviewStylesDr : OrderOverviewStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const { order, updateOrder, isRestored } = React.useContext(OrderContext);
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const [hasErrors, setHasErrors] = React.useState(false);
    const [contactData, setContactData] = React.useState<IContact>();
    const [initialClientAddress, setInitialClientAddress] = React.useState<IAddress | undefined>(undefined);
    const useCustomerAddress = storyblokCommon.configuration.content.order_use_customer_address;

    React.useEffect(() => {
        if (setExternalHasErrors) {
            setExternalHasErrors(hasErrors);
        }
    }, [hasErrors]);

    const updateCustomerData = (customer: IOrderCustomer) => {
        if (accountContext.account && accountContext.accountData) {
            customer = {
                ...customer,
                country: accountContext.accountData?.country,
                language: accountContext.accountData?.language,
                accountId: accountContext.getAccountId(),
            };
        }

        updateOrder({ customer: { ...customer, address: order.customer?.address ?? defaultAddress } });

        if (
            customer.firstName &&
            customer.lastName &&
            customer.phoneNumber &&
            isEmailValid(customer.eMail) &&
            (!storyblokCommon.configuration.content.taxField || (storyblokCommon.configuration.content.taxField && customer.taxNumber))
        ) {
            setHasErrors(false);
        } else {
            setHasErrors(true);
        }
    };

    const isSet = (value?: string): boolean => {
        return value != null && value.length > 0;
    };

    const hasPickUpService = (manufacturer: string | undefined): boolean => {
        if (manufacturer) {
            const manufacturerData = storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === manufacturer);
            if (manufacturerData) {
                return typeof manufacturerData.pickup_service === 'boolean' ? manufacturerData.pickup_service : false;
            }
        }
        return false;
    };

    const getContactData = (): IContact => {
        const data = {
            firstName: order.customer?.firstName ?? '',
            lastName: order.customer?.lastName ?? '',
            eMail: order.customer?.eMail ?? '',
            phoneNumber: order.customer?.phoneNumber ?? '',
            taxNumber: order.customer?.taxNumber ?? '',
            address: order.customer?.address ?? defaultAddress,
        };

        if (accountContext.accountData) {
            if (!data.firstName) {
                data.firstName = accountContext.accountData.firstName ?? '';
            }
            if (!data.lastName) {
                data.lastName = accountContext.accountData.lastName ?? '';
            }
            if (!data.eMail) {
                data.eMail = accountContext.accountData.eMail ?? '';
            }
            if (!data.phoneNumber) {
                data.phoneNumber = accountContext.accountData.phoneNumber ?? '';
            }
        }
        return data;
    };

    const enterClientAddress = (address: IAddress): void => {
        updateOrder({
            customer: { ...(order.customer as IOrderCustomer), address },
        });
    };
    const getClientAddress = (addressId: string): IAddress => {
        return (accountContext.addresses && accountContext.addresses.find((a: IAddress) => a.id === addressId)) ?? defaultAddress;
    };
    const pickClientAddress = (addressId: string): void => {
        enterClientAddress(getClientAddress(addressId));
    };
    const copyClientAdress = (): void => {
        if (isAddressValid(order.invoice?.address)) {
            setInitialClientAddress({ ...(order.invoice?.address ?? defaultAddress) });
            enterClientAddress(order.invoice?.address ?? defaultAddress);
        }
    };

    const updateInvoiceNote = (invoiceNote: string) => {
        updateOrder({ invoiceNote });
    };
    const updatePurchaseOrderFile = (purchaseOrderFile: File | undefined) => {
        updateOrder({ purchaseOrderFile });
    };

    React.useEffect(() => {
        if (accountContext.account) {
            if (accountContext.accountData) {
                const customerData: IOrderCustomer = {
                    eMail: accountContext.accountData?.eMail ?? '',
                    firstName: accountContext.accountData?.firstName,
                    lastName: accountContext.accountData?.lastName,
                };
                updateCustomerData(customerData);
            } else {
                loadingAnimationContext.showLoadingAnimation();
                accountContext.accountDataRequest().finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
            }
        }
    }, [accountContext]);

    React.useEffect(() => {
        if (isRestored) {
            setContactData(getContactData());
            const address: IAddress = order.customer?.address ?? defaultAddress;
            setInitialClientAddress(address);
        }
    }, [isRestored, accountContext.accountData]);

    return (
        <>
            {OrderOverviewStyles && FormStyles && isRestored && contactData && (
                <div className={OrderOverviewStyles.orderOverview}>
                    <div className={OrderOverviewStyles.header}>
                        <h5>{blok.headline}</h5>

                        <p>
                            <strong>{blok.copytext_guest}</strong>
                        </p>
                        <ContactForm
                            onChange={updateCustomerData}
                            showErrors={showErrors}
                            initialData={contactData}
                            storyblokCommon={storyblokCommon}
                        />

                        {(blok.invoice_note_label || blok.purchase_order_label) && (
                            <div className={FormStyles.formGrid}>
                                {blok.invoice_note_label && (
                                    <div className={FormStyles.formField}>
                                        <label htmlFor={'invoice-note'}>{blok.invoice_note_label}</label>
                                        <input
                                            id={'invoice-note'}
                                            name={'invoice-note'}
                                            type="text"
                                            maxLength={40}
                                            onChange={(e) => updateInvoiceNote(e.target.value)}
                                            onBlur={(e) => updateInvoiceNote(e.target.value.trim())}
                                            value={order.invoiceNote ?? ''}
                                            placeholder={blok.invoice_note_placeholder}
                                        />
                                    </div>
                                )}
                                {blok.purchase_order_label && (
                                    <div className={FormStyles.formField}>
                                        <label className={OrderOverviewStyles.label} htmlFor={'cost-estimate-order-purchase-order-file'}>
                                            {blok.purchase_order_label}
                                        </label>
                                        <HsFileUpload
                                            id={'order-overview-purchase-order-file'}
                                            placeholder={blok.purchase_order_placeholder}
                                            storyblokCommon={storyblokCommon}
                                            onChange={(e) => updatePurchaseOrderFile(e)}
                                        >
                                            {blok.purchase_order_add_button}
                                        </HsFileUpload>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {useCustomerAddress && (
                        <div className={OrderOverviewStyles.box}>
                            <div className={OrderOverviewStyles.headline}>
                                <p>
                                    <strong>{blok.customer_address_label}</strong>
                                </p>
                            </div>
                            <div className={OrderOverviewStyles.content}>
                                {accountContext.account && accountContext.addresses ? (
                                    <>
                                        {initialClientAddress && (
                                            <div className={OrderOverviewStyles.addressListPicker}>
                                                <AddressListPicker
                                                    selectedId={initialClientAddress.id}
                                                    onAddressChange={pickClientAddress}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <HsButton
                                            className={OrderOverviewStyles.button}
                                            appearance="secondary"
                                            onClick={copyClientAdress}
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.address_copy_button}
                                        </HsButton>
                                        <>
                                            {initialClientAddress && (
                                                <AddressForm
                                                    initialAddress={initialClientAddress}
                                                    onChange={enterClientAddress}
                                                    showErrors={showErrors}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            )}
                                        </>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={OrderOverviewStyles.box}>
                        <div className={OrderOverviewStyles.headline}>
                            <p>
                                <strong>{blokFrom.headline}</strong>
                            </p>
                            {hasPickUpService(order.manufacturer) && (
                                <div className={OrderOverviewStyles.button}>
                                    <HsLink
                                        onClick={() => navigate(self.location.pathname, { state: { step: 'delivery' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.change_button}
                                    </HsLink>
                                </div>
                            )}
                        </div>
                        <div className={OrderOverviewStyles.content}>
                            {order.deliveryFromCustomer?.type === 'pickup' ? (
                                <>
                                    <OverviewEntry
                                        title={blokFrom.headline}
                                        text={
                                            blokFrom.pickup_service_date_label +
                                            ': ' +
                                            intl.formatDate(order.deliveryFromCustomer.pickupDetails?.date, {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })
                                        }
                                        underline={true}
                                    />

                                    <OverviewEntry title={blokFrom.pickup_service_address_label} />

                                    {isAddressValid(order.deliveryFromCustomer?.pickupDetails?.address) && (
                                        <OverviewAddress
                                            address={order.deliveryFromCustomer?.pickupDetails?.address ?? defaultAddress}
                                            underline={true}
                                            storyblokCommon={storyblokCommon}
                                        />
                                    )}
                                    {isSet(order.deliveryFromCustomer?.pickupDetails?.phone) && (
                                        <OverviewEntry
                                            title={blokFrom.pickup_service_phone_label}
                                            text={order.deliveryFromCustomer?.pickupDetails?.phone}
                                            underline={isSet(order.deliveryFromCustomer?.pickupDetails?.notes)}
                                        />
                                    )}
                                    {isSet(order.deliveryFromCustomer?.pickupDetails?.notes) && (
                                        <OverviewEntry
                                            title={blokFrom.pickup_service_note_label}
                                            text={order.deliveryFromCustomer?.pickupDetails?.notes}
                                        />
                                    )}
                                </>
                            ) : (
                                <OverviewEntry title={blokFrom.self_organized_label} underline={false} />
                            )}
                        </div>
                    </div>

                    {order.deliveryToCustomer?.type === 'self_pickup' ? (
                        <div className={OrderOverviewStyles.box}>
                            <div className={OrderOverviewStyles.headline}>
                                <p>
                                    <strong>{blokTo.headline}</strong>
                                </p>
                                <div className={OrderOverviewStyles.button}>
                                    <HsLink
                                        onClick={() => navigate(self.location.pathname, { state: { step: 'delivery' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.change_button}
                                    </HsLink>
                                </div>
                            </div>
                            <div className={OrderOverviewStyles.content}>
                                <p>{blokTo.self_pickup_label}</p>
                            </div>
                        </div>
                    ) : (
                        <div className={OrderOverviewStyles.box}>
                            <div className={OrderOverviewStyles.headline}>
                                <p>
                                    <strong>{blokTo.headline}</strong>
                                </p>
                                <div className={OrderOverviewStyles.button}>
                                    <HsLink
                                        onClick={() => navigate(self.location.pathname, { state: { step: 'delivery' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.change_button}
                                    </HsLink>
                                </div>
                            </div>
                            <div className={OrderOverviewStyles.content}>
                                <OverviewEntry title={blokTo.return_shipping_label} underline={true} />
                                <OverviewEntry title={blokTo.address_headline} />
                                {isAddressValid(order.deliveryToCustomer?.address) && (
                                    <OverviewAddress
                                        address={order.deliveryToCustomer?.address ?? defaultAddress}
                                        underline={!!order.deliveryToCustomer?.notes}
                                        storyblokCommon={storyblokCommon}
                                    />
                                )}
                                {isSet(order.deliveryToCustomer?.notes) && (
                                    <OverviewEntry title={blokTo.address_note_label} text={order.deliveryToCustomer?.notes} />
                                )}
                            </div>
                        </div>
                    )}
                    <div className={OrderOverviewStyles.box}>
                        <div className={OrderOverviewStyles.headline}>
                            <p>
                                <strong>{blokPayment.headline}</strong>
                            </p>
                            <div className={OrderOverviewStyles.button}>
                                <HsLink
                                    onClick={() => navigate(self.location.pathname, { state: { step: 'payment' } })}
                                    storyblokCommon={storyblokCommon}
                                >
                                    {blok.change_button}
                                </HsLink>
                            </div>
                        </div>
                        <div className={OrderOverviewStyles.content}>
                            <OverviewEntry title={blokPayment.address_headline} />
                            {isAddressValid(order.invoice?.address) && (
                                <OverviewAddress
                                    address={order.invoice?.address ?? defaultAddress}
                                    underline={true}
                                    storyblokCommon={storyblokCommon}
                                />
                            )}
                            {isSet(order.invoice?.customerOrderNumber) && (
                                <OverviewEntry
                                    title={blokPayment.order_number_label}
                                    text={order.invoice?.customerOrderNumber}
                                    underline={true}
                                />
                            )}
                            <OverviewEntry
                                title={blokPayment.payment_method_headline}
                                text={blokPayment.payment_method_invoice_label}
                                additionalText={blokPayment.payment_method_note}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderOverview;
