import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AdditionalService } from '../../../_shared/interfaces/order';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as styles from './additional-services.module.scss';
import { OrderContext } from '../../../context/order/order.context';
import { IArticle } from '../../../_shared/interfaces/article';
type Props = {
    service: AdditionalService;
    storyblokCommon: StoryblokCommon;
    productName: string;
    articleIndex: number;
};

const AdditionalServiceOption: FC<Props> = ({
    service = {} as AdditionalService,
    storyblokCommon: {
        configuration: { content: { theme = 'hs', currency = '', order_use_amount_selection: useAmountSelection = false } = {} } = {},
    } = {},
    productName,
    articleIndex,
}) => {
    const intl = useIntl();
    const { name = '', price = 0, id = '', copytext = { content: [{}] } } = service;
    const { order, updateOrder } = useContext(OrderContext);
    const currrentTheme = theme === 'dr' ? FormStylesDr : FormStylesHs;

    const currentArticle = order.articles?.[articleIndex];
    let additionalServices = [...(currentArticle?.additionalServices || [])];
    let currentAdditionalService = additionalServices.find((n) => n.id === id);
    if (!currentAdditionalService) {
        currentAdditionalService = { name, price, id, amount: 0 } as AdditionalService;
        additionalServices.push(currentAdditionalService);
    }

    const currentAmount = currentAdditionalService.amount ?? 0;
    const updateAdditionalServiceAmount = (amount: number) => {
        if (currentAdditionalService) {
            currentAdditionalService.amount = amount;
        }
        additionalServices = additionalServices.filter((n) => n.amount > 0);
        updateOrder({
            articles: [
                ...(order.articles as IArticle[])?.slice(0, articleIndex),
                { ...currentArticle, additionalServices },
                ...(order.articles as IArticle[])?.slice(articleIndex + 1),
            ] as IArticle[],
        });
    };
    const increaseAmount = () => {
        if (currentAmount < 99) {
            updateAdditionalServiceAmount(currentAmount + 1);
        }
    };
    const decreaseAmount = () => {
        if (currentAmount > 0) {
            updateAdditionalServiceAmount(currentAmount - 1);
        }
    };
    const toggleCheckbox = () => {
        if (currentAmount > 0) {
            updateAdditionalServiceAmount(0);
        } else {
            updateAdditionalServiceAmount(1);
        }
    };

    const formattedPrice = intl.formatNumber(price, {
        style: 'currency',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currency,
    });
    const copytextHtml = getRichTextMarkup(copytext);
    return useAmountSelection ? (
        <li className={styles.additionalServiceItem}>
            <div>
                <p>{name}</p>
                <div className={styles.additionalServiceItemDescription}>
                    <p>i</p>
                    <div dangerouslySetInnerHTML={copytextHtml} />
                </div>
            </div>
            <div>
                <div className={styles.additionalServiceAmountSelection}>
                    <div onClick={decreaseAmount}>&ndash;</div>
                    <div>{currentAmount}</div>
                    <div onClick={increaseAmount}>+</div>
                </div>
            </div>
            <div>
                <p>{formattedPrice}</p>
            </div>
        </li>
    ) : (
        <li className={styles.additionalServiceItem}>
            <div>
                <input type="checkbox" checked={currentAmount > 0} id={id} onChange={toggleCheckbox} />
                <label className={currrentTheme.checkboxLabel} htmlFor={id} />
                <h6 className={styles.additionalServiceItemName} onClick={toggleCheckbox}>
                    {name} ({formattedPrice})
                </h6>
                <div className={styles.additionalServiceItemDescription}>
                    <p>i</p>
                    <div dangerouslySetInnerHTML={copytextHtml} />
                </div>
            </div>
        </li>
    );
};

export default AdditionalServiceOption;
