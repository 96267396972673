import React, { FC, useContext } from 'react';
import * as styles from './additional-services.module.scss';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import AdditionalServicesList from './additional-services-list';
import Buttons from './additional-services-buttons';
import { OrderContext } from '../../../context/order/order.context';
import { getAdditionalServiceProducts } from '../../../lib/order/order';
import { OrderServicesStoryblok } from '../../../_shared/interfaces/storyblok';
import * as AdditionalServicesStyles from './additional-services.module.scss';

type Props = {
    blokServices: OrderServicesStoryblok;
    storyblokCommon: StoryblokCommon;
};

const AdditionalServicesCard: FC<Props> = ({ blokServices, storyblokCommon }) => {
    const { order } = useContext(OrderContext);
    const additionalServicesProducts = getAdditionalServiceProducts(order, storyblokCommon, storyblokCommon.workshopServices.content);

    const themeName = storyblokCommon.configuration.content.theme === 'dr' ? 'dr' : 'hs';
    return (
        <div className={`${styles.card} ${styles[themeName]}`}>
            <div className={AdditionalServicesStyles.header}>
                <h3>{blokServices.as_headline}</h3>
                <div
                    className={styles.descriptionCopytext}
                    dangerouslySetInnerHTML={getRichTextMarkup(blokServices.as_copytext ?? { content: [] })}
                />
            </div>
            {additionalServicesProducts.map(({ serviceId, additionalServices, productName, articleIndex }) => (
                <AdditionalServicesList
                    key={articleIndex}
                    services={additionalServices}
                    productName={productName}
                    articleIndex={articleIndex}
                    storyblokCommon={storyblokCommon}
                    blokServices={blokServices}
                />
            ))}
            <Buttons storyblokCommon={storyblokCommon} blokServices={blokServices} />
        </div>
    );
};

export default AdditionalServicesCard;
