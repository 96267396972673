import React, { FC, useContext, useMemo } from 'react';
import { IProductOptions } from '../services';
import { IArticle } from '../../../../_shared/interfaces/article';
import { getOptionByLabel } from '../../../../lib/article/article';
import { getRichTextMarkup, StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import {
    OrderServicesArticleStoryblok,
    OrderServicesStoryblok,
    OrderShoppingCartStoryblok,
    ProductStoryblok,
} from '../../../../_shared/interfaces/storyblok';
import * as FormStylesHs from '../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../_scss/modules/_forms.dr.module.scss';
import * as ArticleStylesHs from './services-article.hs.module.scss';
import * as ArticleStylesDr from './services-article.dr.module.scss';
import { OrderContext } from '../../../../context/order/order.context';
import { trackEvent } from '../../../../lib/track/track';
import HsButton from '../../../_common/hs-button/hs-button';
import HsButtonSimple from '../../../_common/hs-button-simple/hs-button-simple';
import { ShoppingCartItem } from '../../../../_shared/interfaces/order';

type Props = {
    blok: OrderServicesArticleStoryblok;
    blokServices: OrderServicesStoryblok;
    blokShoppingCart: OrderShoppingCartStoryblok;
    storyblokCommon: StoryblokCommon;
    productOptions: IProductOptions;
    articleIndex: number;
    showErrors?: boolean;
    manufacturer?: string;
    initialValues: IArticle;
    deviceOnly?: boolean;
    children?: any;
    onRemove: () => void;
    shoppingCartItem: ShoppingCartItem;
};

const Article: FC<Props> = ({
    blok,
    blokServices,
    blokShoppingCart,
    storyblokCommon,
    productOptions,
    articleIndex,
    showErrors,
    manufacturer,
    initialValues,
    deviceOnly,
    children,
    onRemove,
    shoppingCartItem,
}) => {
    const ArticleStyles = storyblokCommon.configuration.content.theme === 'dr' ? ArticleStylesDr : ArticleStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const useAmountSelection = storyblokCommon.configuration.content.order_use_amount_selection;
    const {
        updateOrder,
        order: { articles = [] },
    } = useContext(OrderContext);
    const [trackSerialNumber, setTrackSerialNumber] = React.useState(true);
    const [trackSoftwareVersion, setTrackSoftwareVersion] = React.useState(true);
    const isServiceConsultantAvailable = (): boolean => {
        const { products = [] } = storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === manufacturer) ?? {};
        const originalProduct: ProductStoryblok = products.find((p) => p.name === currentArticle.product) ?? ({} as ProductStoryblok);
        return !!originalProduct.service_consultant_available;
    };

    const updateArticle = (
        value: string | string[] | number,
        attributeName: 'product' | 'service' | 'notes' | 'serialNumber' | 'softwareVersion' | 'amount',
    ): void => {
        currentArticle[attributeName] = value;
        if ('product' === attributeName) {
            currentArticle.service = '';
            currentArticle.softwareVersion = '';
        }
        updateOrder({ articles: Object.assign([...articles], { [articleIndex]: currentArticle }) });
        trackInteraction(String(value), attributeName, articleIndex);
    };

    const trackInteraction = (
        value: string,
        attributeName: 'product' | 'service' | 'notes' | 'serialNumber' | 'softwareVersion',
        index: number,
    ): void => {
        let trackData: any;

        switch (attributeName) {
            case 'product':
                trackData = value ? { name: `Product|count:${index + 1}`, value: { product: value } } : null;
                break;
            case 'service':
                trackData = value
                    ? {
                          name: 'Service & Accessory',
                          value: {
                              service: servicesOptions.find((x) => x.value === value)?.label,
                          },
                      }
                    : null;
                break;
            case 'serialNumber':
                if (trackSerialNumber) {
                    setTrackSerialNumber(false);
                    trackData = { name: 'Serial Number' };
                }
                break;
            case 'softwareVersion':
                if (trackSoftwareVersion) {
                    setTrackSoftwareVersion(false);
                    trackData = { name: 'Software Version' };
                }
                break;
        }
        if (trackData) {
            trackEvent('serviceorder.fieldChanged', {
                serviceorder: { type: 'workshop_service', name: '1:ServiceSelection' },
                serviceorderField: trackData,
            });
        }
    };

    const getUpdatedArticle = (article: IArticle): IArticle => {
        if (article.product && manufacturer) {
            const originalData = getOptionByLabel(productOptions[manufacturer], article.product);
            return {
                value: article.product,
                serialNumber: originalData?.isMyDevice && originalData.serialNumber ? originalData.serialNumber : article.serialNumber,
                softwareVersion:
                    originalData?.isMyDevice && originalData.softwareVersion ? originalData.softwareVersion : article.softwareVersion,
                serialNumberRepresentation:
                    originalData?.isMyDevice && originalData?.serialNumber ? 'hidden' : originalData?.serialNumber ?? 'hidden',
                softwareVersionRepresentation:
                    originalData?.isMyDevice && originalData?.softwareVersion ? 'hidden' : originalData?.softwareVersion ?? 'hidden',
                deviceId: originalData?.isMyDevice ? originalData.deviceId : undefined,
                product: originalData?.value ?? '',
                service: article.service,
                amount: article.amount,
                notes: article.notes,
                image: originalData?.image,
            } as IArticle;
        }
        return article;
    };

    const getServicesOptions = (currentProduct: string) => {
        const $default = [{ label: storyblokCommon.configuration.content.select_default, value: '' }];
        if (!Boolean(manufacturer)) return $default;
        for (const { options = [] } of productOptions[manufacturer as string]) {
            for (const { label, services = [] } of options) {
                if (label === currentProduct && services.length > 0) return services;
            }
        }
        return $default;
    };

    const currentArticle = getUpdatedArticle(initialValues);
    const servicesOptions = useMemo(() => getServicesOptions(currentArticle.product), [currentArticle.product]);
    const { product = '' } = articles[articleIndex] ?? {};

    const [articleAmount, setArticleAmount] = React.useState(currentArticle.amount ?? 1);
    const updateArticleAmount = (): void => {
        const value = parseInt(articleAmount, 10);
        if (!isNaN(value) && value > 0) {
            setArticleAmount(value);
            updateArticle(value, 'amount');
            if (value > 1) {
                if (!Array.isArray(currentArticle.serialNumber)) {
                    updateArticle(serialNumber ? [serialNumber] : [], 'serialNumber');
                    setSerialNumber('');
                } else if (currentArticle.serialNumber.length > value) {
                    updateArticle([...currentArticle.serialNumber.slice(0, value)], 'serialNumber');
                }
                if (!Array.isArray(currentArticle.softwareVersion)) {
                    updateArticle(softwareVersion ? [softwareVersion] : [], 'softwareVersion');
                    setSoftwareVersion('');
                } else if (currentArticle.softwareVersion.length > value) {
                    updateArticle([...currentArticle.softwareVersion.slice(0, value)], 'softwareVersion');
                }
            } else {
                if (Array.isArray(currentArticle.serialNumber)) {
                    setSerialNumber(currentArticle.serialNumber[0]);
                    updateArticle(currentArticle.serialNumber[0] ?? '', 'serialNumber');
                }
                if (Array.isArray(currentArticle.softwareVersion)) {
                    setSoftwareVersion(currentArticle.softwareVersion[0]);
                    updateArticle(currentArticle.softwareVersion[0] ?? '', 'softwareVersion');
                }
            }
        } else {
            setArticleAmount(currentArticle.amount);
        }
    };
    React.useEffect(() => {
        setArticleAmount(currentArticle.amount);
    }, [currentArticle.amount]);

    const [serialNumber, setSerialNumber] = React.useState(
        (Array.isArray(currentArticle.serialNumber)
            ? currentArticle.serialNumber[currentArticle.serialNumber.length - 1]
            : currentArticle.serialNumber) ?? '',
    );
    React.useEffect(() => {
        if (Array.isArray(currentArticle.serialNumber)) {
            setSerialNumber('');
        } else {
            setSerialNumber(currentArticle.serialNumber ?? '');
        }
    }, [currentArticle.serialNumber]);
    const updateSerialNumber = (value: string): void => {
        setSerialNumber(value);
        if (currentArticle.amount === 1) {
            updateArticle(value.trim(), 'serialNumber');
        }
    };
    const addSerialNumber = (): void => {
        const value = serialNumber.trim();
        if (value !== '' && currentArticle.serialNumber.length < currentArticle.amount) {
            updateArticle([...currentArticle.serialNumber, value], 'serialNumber');
            setSerialNumber('');
        }
    };
    const removeSerialNumber = (index: number): void => {
        updateArticle([...currentArticle.serialNumber.slice(0, index), ...currentArticle.serialNumber.slice(index + 1)], 'serialNumber');
    };
    const [softwareVersion, setSoftwareVersion] = React.useState(
        (Array.isArray(currentArticle.softwareVersion)
            ? currentArticle.softwareVersion[currentArticle.softwareVersion.length - 1]
            : currentArticle.softwareVersion) ?? '',
    );
    const updateSoftwareVersion = (value: string): void => {
        setSoftwareVersion(value);
        if (currentArticle.amount === 1) {
            updateArticle(value.trim(), 'softwareVersion');
        }
    };
    const addSoftwareVersion = (): void => {
        const value = softwareVersion.trim();
        if (value !== '' && currentArticle.softwareVersion.length < currentArticle.amount) {
            updateArticle([...currentArticle.softwareVersion, value], 'softwareVersion');
            setSoftwareVersion('');
        }
    };
    const removeSoftwareVersion = (index: number): void => {
        updateArticle(
            [...currentArticle.softwareVersion.slice(0, index), ...currentArticle.softwareVersion.slice(index + 1)],
            'softwareVersion',
        );
    };

    const [editArticle, setEditArticle] = React.useState(true);
    const toggleEditArticle = () => {
        setEditArticle(!editArticle);
    };

    const serialNumberFieldError =
        showErrors &&
        manufacturer &&
        currentArticle.product &&
        currentArticle.serialNumberRepresentation === 'mandatory' &&
        (!currentArticle.serialNumber ||
            (Array.isArray(currentArticle.serialNumber) && currentArticle.serialNumber.length < currentArticle.amount));

    const softwareVersionFieldError =
        showErrors &&
        manufacturer &&
        currentArticle.product &&
        currentArticle.softwareVersionRepresentation === 'mandatory' &&
        (!currentArticle.softwareVersion ||
            (Array.isArray(currentArticle.softwareVersion) && currentArticle.softwareVersion.length < currentArticle.amount));

    return (
        <>
            {ArticleStyles && FormStyles && (
                <div className={ArticleStyles.articleWrapper}>
                    <div className={ArticleStyles.articleEditButtons}>
                        {useAmountSelection && (
                            <HsButtonSimple onClick={toggleEditArticle} storyblokCommon={storyblokCommon}>
                                {editArticle ? blokServices.save_article : blokServices.edit_article}
                            </HsButtonSimple>
                        )}
                        {articleIndex > 0 && (
                            <HsButtonSimple onClick={onRemove} storyblokCommon={storyblokCommon}>
                                {blokServices.delete_article}
                            </HsButtonSimple>
                        )}
                    </div>
                    {!editArticle && (
                        <div className={ArticleStyles.articleSummary}>
                            <div>
                                {currentArticle.image ? (
                                    <img src={currentArticle.image} />
                                ) : (
                                    <div className={ArticleStyles.imagePlaceholder} />
                                )}
                            </div>
                            <div>
                                <h6>
                                    {currentArticle.amount > 1 && currentArticle.amount + ' x '}
                                    {product}
                                </h6>
                                {currentArticle.serialNumber?.length > 0 && (
                                    <p>
                                        <span>{blok.sn_label}:</span>{' '}
                                        {currentArticle.serialNumber?.join?.(', ') ?? currentArticle.serialNumber}
                                    </p>
                                )}
                                {currentArticle.softwareVersion?.length > 0 && (
                                    <p>
                                        <span>{blok.sv_label}:</span>{' '}
                                        {currentArticle.softwareVersion?.join?.(', ') ?? currentArticle.softwareVersion}
                                    </p>
                                )}
                                {currentArticle.notes && (
                                    <p>
                                        <span>{blok.notes_label}:</span> {currentArticle.notes}
                                    </p>
                                )}
                            </div>
                            <div>
                                {shoppingCartItem && (
                                    <>
                                        <p>{shoppingCartItem.service.name}</p>
                                        <p>
                                            {currentArticle.amount > 1 && blok.one_piece} {shoppingCartItem.pricePerPiece.formatted}
                                        </p>
                                        <small>{blokShoppingCart.vat_excluded}</small>
                                        <h6>{currentArticle.amount > 1 && shoppingCartItem.price.formatted}</h6>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {editArticle && (
                        <div className={ArticleStyles.orderServicesArticle}>
                            <form>
                                <div className={ArticleStyles.formFieldAmountWrapper}>
                                    <div className={FormStyles.formField}>
                                        <label htmlFor={'order-services-product-' + articleIndex}>{blok.product_label}*</label>

                                        <select
                                            id={'order-services-product-' + articleIndex}
                                            name={'order-services-product-' + articleIndex}
                                            disabled={!manufacturer}
                                            onChange={(e) => updateArticle(e.target.value, 'product')}
                                            value={product}
                                            className={
                                                showErrors && manufacturer && !currentArticle.product ? FormStyles.inputValidationError : ''
                                            }
                                        >
                                            <option value="">{storyblokCommon.configuration.content.select_default}</option>
                                            {manufacturer &&
                                                productOptions[manufacturer].map((group: any, groupIndex: number) => {
                                                    return (
                                                        <optgroup key={groupIndex} label={group.label}>
                                                            {group.options.map(
                                                                (
                                                                    option: {
                                                                        value: string;
                                                                        label: string;
                                                                    },
                                                                    optionIndex: number,
                                                                ) => {
                                                                    return <option key={optionIndex}>{option.label}</option>;
                                                                },
                                                            )}
                                                        </optgroup>
                                                    );
                                                })}
                                        </select>
                                        {showErrors && manufacturer && !currentArticle.product && (
                                            <p className={FormStyles.error}>{blok.product_error}</p>
                                        )}
                                    </div>
                                    {useAmountSelection && (
                                        <div className={FormStyles.formField}>
                                            <label htmlFor={'order-services-product-amount-' + articleIndex}>{blok.amount_label}</label>
                                            <input
                                                id={'order-services-product-amount-' + articleIndex}
                                                name={'order-services-product-amount-' + articleIndex}
                                                maxLength={2}
                                                value={articleAmount}
                                                onChange={(e) => setArticleAmount(e.target.value)}
                                                onKeyPress={(e) => {
                                                    if (e.charCode === 13) {
                                                        updateArticleAmount();
                                                    }
                                                }}
                                                onBlur={(e) => updateArticleAmount()}
                                                onFocus={(e) => e.target.select()}
                                            />
                                        </div>
                                    )}
                                </div>
                                {currentArticle.product && currentArticle.serialNumberRepresentation !== 'hidden' && (
                                    <div className={FormStyles.formField}>
                                        <label htmlFor={'order-services-serial-number-' + articleIndex}>
                                            {blok.sn_label}
                                            {currentArticle.serialNumberRepresentation === 'mandatory' && <>*</>}
                                        </label>
                                        <input
                                            id={'order-services-serial-number-' + articleIndex}
                                            name={'order-services-serial-number-' + articleIndex}
                                            maxLength={40}
                                            value={serialNumber}
                                            placeholder={blok.sn_placeholder}
                                            onChange={(e) => {
                                                updateSerialNumber(e.target.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.charCode === 13) {
                                                    addSerialNumber();
                                                }
                                            }}
                                            className={
                                                (serialNumberFieldError ? FormStyles.inputValidationError : '') +
                                                (currentArticle.amount > 1 ? ' ' + ArticleStyles.showAddButton : '')
                                            }
                                        />
                                        {currentArticle.amount > 1 && (
                                            <>
                                                <HsButton
                                                    id={'button-add-serial-number-' + articleIndex}
                                                    icon="plus"
                                                    onClick={addSerialNumber}
                                                    className={ArticleStyles.addButton}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                                <div className={ArticleStyles.valueTags}>
                                                    {Array.isArray(currentArticle.serialNumber) &&
                                                        currentArticle.serialNumber.map((_serialNumber, i) => (
                                                            <span key={i} onClick={() => removeSerialNumber(i)}>
                                                                {_serialNumber}
                                                            </span>
                                                        ))}
                                                </div>
                                            </>
                                        )}
                                        {serialNumberFieldError && currentArticle.amount === 1 && (
                                            <p className={FormStyles.error}>{blok.sn_error}</p>
                                        )}
                                        {serialNumberFieldError && currentArticle.amount > 1 && (
                                            <p className={FormStyles.error}>
                                                {blok.sn_error_multiple
                                                    ?.replace('<AMOUNT>', currentArticle.amount)
                                                    .replace('<REMAINING>', currentArticle.amount - currentArticle.serialNumber.length)}
                                            </p>
                                        )}
                                    </div>
                                )}

                                {currentArticle.product && currentArticle.softwareVersionRepresentation !== 'hidden' && (
                                    <div className={FormStyles.formField}>
                                        <label htmlFor={'order-services-software-version-' + articleIndex}>
                                            {blok.sv_label}
                                            {currentArticle.softwareVersionRepresentation === 'mandatory' && <>*</>}
                                        </label>
                                        <input
                                            id={'order-services-software-version-' + articleIndex}
                                            name={'order-services-software-version-' + articleIndex}
                                            maxLength={40}
                                            value={softwareVersion}
                                            placeholder={blok.sv_placeholder}
                                            onChange={(e) => {
                                                updateSoftwareVersion(e.target.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.charCode === 13) {
                                                    addSoftwareVersion();
                                                }
                                            }}
                                            className={
                                                (softwareVersionFieldError ? FormStyles.inputValidationError : '') +
                                                (currentArticle.amount > 1 ? ' ' + ArticleStyles.showAddButton : '')
                                            }
                                        />
                                        {currentArticle.amount > 1 && (
                                            <>
                                                <HsButton
                                                    id={'button-add-software-version-' + articleIndex}
                                                    icon="plus"
                                                    onClick={addSoftwareVersion}
                                                    className={ArticleStyles.addButton}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                                <div className={ArticleStyles.valueTags}>
                                                    {Array.isArray(currentArticle.softwareVersion) &&
                                                        currentArticle.softwareVersion.map((_softwareVersion, i) => (
                                                            <span key={i} onClick={() => removeSoftwareVersion(i)}>
                                                                {_softwareVersion}
                                                            </span>
                                                        ))}
                                                </div>
                                            </>
                                        )}
                                        {softwareVersionFieldError && currentArticle.amount === 1 && (
                                            <p className={FormStyles.error}>{blok.sv_error}</p>
                                        )}
                                        {softwareVersionFieldError && currentArticle.amount > 1 && (
                                            <p className={FormStyles.error}>
                                                {blok.sv_error_multiple
                                                    ?.replace('<AMOUNT>', currentArticle.amount)
                                                    .replace('<REMAINING>', currentArticle.amount - currentArticle.softwareVersion.length)}
                                            </p>
                                        )}
                                    </div>
                                )}

                                {!deviceOnly && (
                                    <>
                                        <div className={FormStyles.formField}>
                                            <label htmlFor={'order-services-service-' + articleIndex}>{blok.service_label}*</label>

                                            {blok.service_info && isServiceConsultantAvailable() && (
                                                <div
                                                    style={{ paddingBottom: '3px' }}
                                                    className={FormStyles.infoText}
                                                    dangerouslySetInnerHTML={getRichTextMarkup(blok.service_info)}
                                                />
                                            )}

                                            <select
                                                id={'order-services-service-' + articleIndex}
                                                name={'order-services-service-' + articleIndex}
                                                disabled={!(manufacturer && currentArticle.product)}
                                                onChange={(e) => updateArticle(e.target.value, 'service')}
                                                value={currentArticle.service}
                                                className={
                                                    showErrors && manufacturer && currentArticle.product && !currentArticle.service
                                                        ? FormStyles.inputValidationError
                                                        : ''
                                                }
                                            >
                                                <option value="">{storyblokCommon.configuration.content.select_default}</option>
                                                {servicesOptions.map((option: { label: string; value: string }, index) => {
                                                    return (
                                                        <option key={index} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    );
                                                })}
                                            </select>

                                            {showErrors && manufacturer && currentArticle.product && !currentArticle.service && (
                                                <p className={FormStyles.error}>{blok.service_error}</p>
                                            )}
                                        </div>

                                        <div className={[FormStyles.formField, FormStyles.fullWidth].join(' ')}>
                                            <label htmlFor={'order-services-notes-' + articleIndex}>{blok.notes_label}</label>
                                            <textarea
                                                id={'order-services-notes-' + articleIndex}
                                                name={'order-services-notes-' + articleIndex}
                                                value={currentArticle.notes}
                                                maxLength={600}
                                                disabled={!(manufacturer && currentArticle.product && currentArticle.service)}
                                                onChange={(e) => updateArticle(e.target.value, 'notes')}
                                                onBlur={(e) => updateArticle(e.target.value.trim(), 'notes')}
                                                placeholder={blok.notes_placeholder}
                                            />
                                        </div>
                                    </>
                                )}
                            </form>
                            {children}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Article;
