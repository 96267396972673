import React, { FC, memo, useContext } from 'react';
import { useShoppingCartStyles, useShoppingCartEvents } from './shopping-cart-context';
import { ShoppingCartItem, IShoppingCartAdditionalService } from '../../../_shared/interfaces/order';
import { OrderContext } from '../../../context/order/order.context';
import { IArticle } from '../../../_shared/interfaces/article';

type Props = {
    parentIndex: number;
    additionalService: IShoppingCartAdditionalService;
    additionalServiceIndex: number;
};

const ShoppingCartAdditionalService: FC<Props> = ({ additionalService, additionalServiceIndex, parentIndex }) => {
    const styles = useShoppingCartStyles();
    const {
        order: { articles = [] },
        updateOrder,
    } = useContext(OrderContext);

    const removeAdditionalService = () => {
        const { additionalServices, ...rest } = articles[parentIndex];
        const newArticle: IArticle = {
            ...rest,
            additionalServices: additionalServices?.filter((_, i) => i !== additionalServiceIndex),
        };
        updateOrder({ articles: Object.assign(articles, { [parentIndex]: newArticle }) });
    };

    return (
        <>
            <p className={styles.sectionName}>
                {additionalService.amount} x {additionalService.name}
            </p>
            <button className={styles.deleteButton} onClick={removeAdditionalService} />
            <p className={styles.price}>{additionalService.price.formatted.replace('€', '€   ')}</p>
        </>
    );
};

export default memo(
    ShoppingCartAdditionalService,
    (prev, next) =>
        prev.parentIndex === next.parentIndex &&
        prev.additionalService.amount === next.additionalService.amount &&
        prev.additionalService.price.value === next.additionalService.price.value,
);
