import React, { FC, memo } from 'react';
import { AdditionalService } from '../../../_shared/interfaces/order';
import * as styles from './additional-services.module.scss';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import AdditionalServiceOption from './additional-service-option';
import { OrderServicesStoryblok } from '../../../_shared/interfaces/storyblok';

type Props = {
    services: AdditionalService[];
    productName: string;
    articleIndex: number;
    storyblokCommon: StoryblokCommon;
    blokServices: OrderServicesStoryblok;
};

const AdditionalServicesList: FC<Props> = ({ services = [], productName = '', articleIndex, storyblokCommon, blokServices }) => {
    const useAmountSelection = storyblokCommon.configuration.content.order_use_amount_selection;
    return (
        <div className={styles.additionalServices}>
            <p>{blokServices.as_product_headline}</p>
            <h5>{productName}</h5>
            {useAmountSelection && (
                <div className={styles.additionalServiceColHeader}>
                    <div>
                        <p>{blokServices.as_type_headline}</p>
                    </div>
                    <div>
                        <p>{blokServices.as_amount_headline}</p>
                    </div>
                    <div>
                        <p>{blokServices.as_price_headline}</p>
                    </div>
                </div>
            )}
            <ul className={styles.additionalServicesList} key={productName}>
                {services.map((service, index) => (
                    <AdditionalServiceOption
                        key={index}
                        service={service}
                        productName={productName}
                        storyblokCommon={storyblokCommon}
                        articleIndex={articleIndex}
                    />
                ))}
            </ul>
        </div>
    );
};
export default memo(AdditionalServicesList, (prev, next) => prev.productName === next.productName);
