import React, { FC, useMemo } from 'react';
import { useShoppingCartDetails } from './shopping-cart-context';
import NoArticle from './no-article-placeholder';
import ShoppingCartArticle from './shopping-cart-article';
import { ShoppingCartDetails } from '../../../_shared/interfaces/order';
import Separator from './shopping-cart-separator';

const ShoppingCartArticlesList: FC = () => {
    const { items = [] } = useShoppingCartDetails() ?? ({} as ShoppingCartDetails);
    return useMemo(
        () =>
            items.length === 0 ? (
                <NoArticle />
            ) : (
                <ul>
                    {items.map((item, index: number) => (
                        <>
                            {index > 0 && (
                                <li>
                                    <Separator />
                                </li>
                            )}
                            <ShoppingCartArticle key={index} item={item} articleIndex={item.articleIndex} />
                        </>
                    ))}
                </ul>
            ),
        [items, items.length],
    );
};

export default ShoppingCartArticlesList;
