import React from 'react';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import {
    OrderDeliveryFromCustomerStoryblok,
    OrderDeliveryToCustomerStoryblok,
    OrderOverviewStoryblok,
    OrderPaymentStoryblok,
} from '../../../_shared/interfaces/storyblok';
import { OrderContext } from '../../../context/order/order.context';
import ContactForm from '../../_common/contact-form/contact-form';
import OverviewEntry from '../overview/entry/entry';
import { defaultAddress, isAddressValid, isEmailValid } from '../../../lib/helpers/helpers';
import OverviewAddress from '../overview/address/address';
import { AccountContext } from '../../../context/account/account.context';

import * as OrderOverviewStylesDr from '../overview/overview.dr.module.scss';
import * as OrderOverviewStylesHs from '../overview/overview.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import { IContact } from '../../../_shared/interfaces/contact';
import { IOrderCustomer } from '../../../_shared/interfaces/order-customer';
import { useIntl } from 'react-intl';
import { ICostEstimate } from '../../../_shared/interfaces/cost-estimate';
import { globalHistory } from '@reach/router';
import DatePicker, { registerLocale } from 'react-datepicker';
import HsFileUpload from '../../_common/hs-file-upload/hs-file-upload';

import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import ko from 'date-fns/locale/ko';
import nl from 'date-fns/locale/nl';

import '../../../_scss/react-datepicker.scss';
import { IAddress } from '../../../_shared/interfaces/address';

const OrderCostEstimate = ({
    storyblokCommon,
    costEstimate,
    blokOverview,
    blokFrom,
    blokTo,
    blokPayment,
    showErrors,
}: {
    storyblokCommon: StoryblokCommon;
    costEstimate: ICostEstimate;
    blokOverview: OrderOverviewStoryblok;
    blokFrom: OrderDeliveryFromCustomerStoryblok;
    blokTo: OrderDeliveryToCustomerStoryblok;
    blokPayment: OrderPaymentStoryblok;
    showErrors: boolean;
}) => {
    const intl = useIntl();
    const OrderOverviewStyles = storyblokCommon.configuration.content.theme === 'dr' ? OrderOverviewStylesDr : OrderOverviewStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const { order, updateOrder, isRestored, resetOrder } = React.useContext(OrderContext);
    const accountContext = React.useContext(AccountContext);
    const [contactData, setContactData] = React.useState<IContact>();
    const [, setHasErrors] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);
    const useCustomerAddress = storyblokCommon.configuration.content.order_use_customer_address;

    const isWeekday = (date: Date): boolean => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const findFirstValidPickupDate = (): Date => {
        const d = new Date();
        d.setDate(d.getDate() + 2);
        while (!isWeekday(d)) {
            d.setDate(d.getDate() + 1);
        }
        return d;
    };

    const minDate = findFirstValidPickupDate();

    const isValidPickupDate = (): boolean => {
        if (order.deliveryFromCustomer?.type === 'pickup') {
            if (order.deliveryFromCustomer.pickupDetails?.date && new Date(order.deliveryFromCustomer.pickupDetails.date) >= minDate) {
                return true;
            }
            return false;
        }
        return true;
    };

    const isSet = (value?: string): boolean => {
        return value != null && value.length > 0;
    };

    const getContactData = (): IContact => {
        const data = {
            firstName: order.customer?.firstName ?? '',
            lastName: order.customer?.lastName ?? '',
            eMail: order.customer?.eMail ?? '',
            phoneNumber: order.customer?.phoneNumber ?? '',
            taxNumber: order.customer?.taxNumber ?? '',
        };

        if (accountContext.accountData) {
            if (!data.firstName) {
                data.firstName = accountContext.accountData.firstName ?? '';
            }
            if (!data.lastName) {
                data.lastName = accountContext.accountData.lastName ?? '';
            }
            if (!data.eMail) {
                data.eMail = accountContext.accountData.eMail ?? '';
            }
            if (!data.phoneNumber) {
                data.phoneNumber = accountContext.accountData.phoneNumber ?? '';
            }
        }
        return data;
    };

    const updatePurchaseOrderFile = (purchaseOrderFile: File | undefined) => {
        updateOrder({ purchaseOrderFile });
    };

    const updateInvoiceNote = (invoiceNote: string) => {
        updateOrder({ invoiceNote });
    };

    const updatePickupDate = (date: string | undefined) => {
        updateOrder({
            deliveryFromCustomer: {
                ...order.deliveryFromCustomer,
                pickupDetails: { ...order.deliveryFromCustomer?.pickupDetails, date },
            },
        });
    };

    const updateCustomerData = (customer: IOrderCustomer) => {
        if (accountContext.account && accountContext.accountData) {
            customer = {
                ...customer,
                country: accountContext.accountData?.country,
                language: accountContext.accountData?.language,
                accountId: accountContext.getAccountId(),
            };
        }

        updateOrder({ customer });

        if (
            customer.firstName &&
            customer.lastName &&
            customer.phoneNumber &&
            isEmailValid(customer.eMail) &&
            (!storyblokCommon.configuration.content.taxField || (storyblokCommon.configuration.content.taxField && customer.taxNumber))
        ) {
            setHasErrors(false);
        } else {
            setHasErrors(true);
        }
    };

    React.useEffect(() => {
        if (isRestored) {
            setContactData(getContactData());

            if (!isValidPickupDate()) {
                updatePickupDate(minDate.toJSON());
            }
        }
    }, [isRestored, accountContext.accountData]);

    React.useEffect(() => {
        if (storyblokCommon.configuration.content.language === 'de') {
            registerLocale('de', de);
        } else if (storyblokCommon.configuration.content.language === 'fr') {
            registerLocale('fr', fr);
        } else if (storyblokCommon.configuration.content.language === 'ko') {
            registerLocale('ko', ko);
        } else if (storyblokCommon.configuration.content.language === 'nl') {
            registerLocale('nl', nl);
        }
        setIsReady(true);

        // reset order on leave
        return globalHistory.listen(({ action, location }) => {
            if (action === 'PUSH' || action === 'POP') {
                resetOrder();
            }
        });
    }, []);

    return (
        <>
            {isReady && OrderOverviewStyles && FormStyles && isRestored && contactData && (
                <div className={OrderOverviewStyles.orderOverview}>
                    <div className={OrderOverviewStyles.header}>
                        <h5>{blokOverview.headline}</h5>

                        <p style={{ marginBottom: '36px' }}>
                            {storyblokCommon.configuration.content.cost_estimate_label_reference_number} {costEstimate.id}
                        </p>

                        <p>
                            <strong>{blokOverview.copytext_guest}</strong>
                        </p>
                        <ContactForm
                            onChange={updateCustomerData}
                            showErrors={showErrors}
                            initialData={contactData}
                            storyblokCommon={storyblokCommon}
                        />
                        {(blokOverview.invoice_note_label || blokOverview.pickup_date_lable || blokOverview.purchase_order_label) && (
                            <div className={FormStyles.formGrid}>
                                {blokOverview.invoice_note_label && (
                                    <div className={FormStyles.formField}>
                                        <label htmlFor={'invoice-note'}>{blokOverview.invoice_note_label}</label>
                                        <input
                                            id={'invoice-note'}
                                            name={'invoice-note'}
                                            type="text"
                                            maxLength={40}
                                            onChange={(e) => updateInvoiceNote(e.target.value)}
                                            onBlur={(e) => updateInvoiceNote(e.target.value.trim())}
                                            value={order.invoiceNote ?? ''}
                                            placeholder={blokOverview.invoice_note_placeholder}
                                        />
                                    </div>
                                )}
                                {blokOverview.pickup_date_lable && order.deliveryFromCustomer?.type === 'pickup' && (
                                    <div className={FormStyles.formField}>
                                        <label
                                            className={OrderOverviewStyles.label}
                                            htmlFor="cost-estimate-order-delivery-pickup-date-input"
                                        >
                                            {blokOverview.pickup_date_lable}*
                                        </label>
                                        <DatePicker
                                            id="cost-estimate-order-delivery-pickup-date-input"
                                            locale={storyblokCommon.configuration.content.language}
                                            dateFormat="P"
                                            filterDate={isWeekday}
                                            minDate={minDate}
                                            selected={new Date(order.deliveryFromCustomer.pickupDetails?.date ?? minDate)}
                                            onChange={(e) => updatePickupDate(!Array.isArray(e) ? e?.toJSON() : minDate.toJSON())}
                                        />
                                    </div>
                                )}
                                {blokOverview.purchase_order_label && (
                                    <div className={FormStyles.formField}>
                                        <label className={OrderOverviewStyles.label} htmlFor={'cost-estimate-order-purchase-order-file'}>
                                            {blokOverview.purchase_order_label}
                                        </label>
                                        <HsFileUpload
                                            id={'cost-estimate-order-purchase-order-file'}
                                            placeholder={blokOverview.purchase_order_placeholder}
                                            storyblokCommon={storyblokCommon}
                                            onChange={(e) => updatePurchaseOrderFile(e)}
                                        >
                                            {blokOverview.purchase_order_add_button}
                                        </HsFileUpload>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {useCustomerAddress && isAddressValid(order.customer?.address) && (
                        <div className={OrderOverviewStyles.box}>
                            <div className={OrderOverviewStyles.headline}>
                                <p>
                                    <strong>{blokOverview.customer_address_label}</strong>
                                </p>
                            </div>
                            <div className={OrderOverviewStyles.content}>
                                <OverviewAddress
                                    address={order.customer?.address as IAddress}
                                    underline={false}
                                    storyblokCommon={storyblokCommon}
                                />
                            </div>
                        </div>
                    )}

                    <div className={OrderOverviewStyles.box}>
                        <div className={OrderOverviewStyles.headline}>
                            <p>
                                <strong>{blokFrom.headline}</strong>
                            </p>
                        </div>
                        <div className={OrderOverviewStyles.content}>
                            {order.deliveryFromCustomer?.type === 'pickup' ? (
                                <>
                                    <OverviewEntry
                                        title={blokFrom.headline}
                                        text={
                                            blokFrom.pickup_service_date_label +
                                            ': ' +
                                            intl.formatDate(order.deliveryFromCustomer.pickupDetails?.date, {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })
                                        }
                                        underline={true}
                                    />

                                    <OverviewEntry title={blokFrom.pickup_service_address_label} />

                                    {isAddressValid(order.deliveryFromCustomer?.pickupDetails?.address) && (
                                        <OverviewAddress
                                            address={order.deliveryFromCustomer?.pickupDetails?.address ?? defaultAddress}
                                            underline={true}
                                            storyblokCommon={storyblokCommon}
                                        />
                                    )}
                                    {isSet(order.deliveryFromCustomer?.pickupDetails?.phone) && (
                                        <OverviewEntry
                                            title={blokFrom.pickup_service_phone_label}
                                            text={order.deliveryFromCustomer?.pickupDetails?.phone}
                                            underline={isSet(order.deliveryFromCustomer?.pickupDetails?.notes)}
                                        />
                                    )}
                                    {isSet(order.deliveryFromCustomer?.pickupDetails?.notes) && (
                                        <OverviewEntry
                                            title={blokFrom.pickup_service_note_label}
                                            text={order.deliveryFromCustomer?.pickupDetails?.notes}
                                        />
                                    )}
                                </>
                            ) : (
                                <OverviewEntry title={blokFrom.self_organized_label} underline={false} />
                            )}
                        </div>
                    </div>

                    {order.deliveryToCustomer?.type === 'self_pickup' ? (
                        <div className={OrderOverviewStyles.box}>
                            <div className={OrderOverviewStyles.headline}>
                                <p>
                                    <strong>{blokTo.headline}</strong>
                                </p>
                            </div>
                            <div className={OrderOverviewStyles.content}>
                                <p>{blokTo.self_pickup_label}</p>
                            </div>
                        </div>
                    ) : (
                        <div className={OrderOverviewStyles.box}>
                            <div className={OrderOverviewStyles.headline}>
                                <p>
                                    <strong>{blokTo.headline}</strong>
                                </p>
                            </div>
                            <div className={OrderOverviewStyles.content}>
                                <OverviewEntry title={blokTo.return_shipping_label} underline={true} />
                                <OverviewEntry title={blokTo.address_headline} />
                                {isAddressValid(order.deliveryToCustomer?.address) && (
                                    <OverviewAddress
                                        address={order.deliveryToCustomer?.address ?? defaultAddress}
                                        underline={!!order.deliveryToCustomer?.notes}
                                        storyblokCommon={storyblokCommon}
                                    />
                                )}
                                {isSet(order.deliveryToCustomer?.notes) && (
                                    <OverviewEntry title={blokTo.address_note_label} text={order.deliveryToCustomer?.notes} />
                                )}
                            </div>
                        </div>
                    )}
                    <div className={OrderOverviewStyles.box}>
                        <div className={OrderOverviewStyles.headline}>
                            <p>
                                <strong>{blokPayment.headline}</strong>
                            </p>
                        </div>
                        <div className={OrderOverviewStyles.content}>
                            <OverviewEntry title={blokPayment.address_headline} />
                            {isAddressValid(order.invoice?.address) && (
                                <OverviewAddress
                                    address={order.invoice?.address ?? defaultAddress}
                                    underline={true}
                                    storyblokCommon={storyblokCommon}
                                />
                            )}
                            {isSet(order.invoice?.customerOrderNumber) && (
                                <OverviewEntry
                                    title={blokPayment.order_number_label}
                                    text={order.invoice?.customerOrderNumber}
                                    underline={true}
                                />
                            )}
                            <OverviewEntry
                                title={blokPayment.payment_method_headline}
                                text={blokPayment.payment_method_invoice_label}
                                additionalText={blokPayment.payment_method_note}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderCostEstimate;
