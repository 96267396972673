import { IOrder } from '../../_shared/interfaces/order';
import { ManufacturersStoryblok } from '../../_shared/interfaces/storyblok';

export const deepLinkCheck = (location: Location, manufacturers: ManufacturersStoryblok): Promise<IOrder | void> => {
    return new Promise((resolve) => {
        if (location.search) {
            const params = new URLSearchParams(location.search.substring(1));

            if (params.get('m') && isManufacturerValid(params.get('m') ?? '', manufacturers)) {
                let updatedOrder: IOrder = {
                    manufacturer: params.get('m') ?? '',
                };

                if (params.get('p') && isProductValid(params.get('m') ?? '', params.get('p') ?? '', manufacturers)) {
                    updatedOrder = {
                        manufacturer: params.get('m') ?? '',
                        articles: [{ product: params.get('p') ?? '', service: '', amount: 1 }],
                    };
                    if (params.get('s')) {
                        updatedOrder = {
                            manufacturer: params.get('m') ?? '',
                            articles: [
                                {
                                    product: params.get('p') ?? '',
                                    service:
                                        getServiceId(params.get('m') ?? '', params.get('p') ?? '', params.get('s') ?? '', manufacturers) ??
                                        '',
                                    amount: 1,
                                },
                            ],
                        };
                    }
                }
                resolve(updatedOrder);
            } else {
                resolve();
            }
        } else {
            resolve();
        }
    });
};

const isManufacturerValid = (manufacturer: string, manufacturers: ManufacturersStoryblok): boolean => {
    return !!manufacturers.manufacturer.find((m) => m.name === manufacturer);
};

const isProductValid = (manufacturer: string, product: string, manufacturers: ManufacturersStoryblok): boolean => {
    const manufacturerResult = manufacturers.manufacturer.find((m) => m.name === manufacturer);
    if (!manufacturerResult) {
        return false;
    } else {
        const productResult = (manufacturerResult.products as { name: string }[]).find((p) => p.name === product);
        return !!productResult;
    }
};

const getServiceId = (
    manufacturer: string,
    product: string,
    service: string,
    manufacturers: ManufacturersStoryblok,
): string | undefined => {
    const manufacturerResult = manufacturers.manufacturer.find((m) => m.name === manufacturer);
    if (!manufacturerResult) {
        return;
    } else {
        const productResult = (manufacturerResult.products as { name: string; services: { name: string; _uid: string }[] }[]).find(
            (p) => p.name === product,
        );
        if (productResult) {
            const serviceResult = productResult.services.find((s) => s.name === service);
            if (serviceResult) {
                return serviceResult._uid;
            } else {
                return;
            }
        } else {
            return;
        }
    }
};
