import React, { FC, useMemo } from 'react';
import { IShoppingCartService, ShoppingCartItem, IShoppingCartProduct } from '../../../_shared/interfaces/order';
import ShoppingCartAdditionalService from './shopping-cart-additional-service';
import { useShoppingCartStyles, useShoppingCartStoryBlok, useShoppingCartEvents } from './shopping-cart-context';

export type OnRemoveArticleEvent = {
    productName: string;
    serviceId: string;
    serialNumber: string | undefined;
};

export type OnRemoveAdditionalServiceEvent = {
    productName: string;
    serviceId: string;
    productSerialNumber: string | undefined;
    additionalServiceId: string;
    additionalServiceName: string;
};

type Props = {
    item: ShoppingCartItem;
    articleIndex: number;
};

const ShoppingCartArticle: FC<Props> = ({ item, articleIndex }) => {
    const {
        additionalServices = [],
        price: { formatted = '' } = {},
        amount,
        product: { serialNumber = '', ...product } = {} as IShoppingCartProduct,
        service: { id = '', ...service } = {} as IShoppingCartService,
    } = item ?? {};
    const styles = useShoppingCartStyles();
    const blok = useShoppingCartStoryBlok();
    const { setFlaggedArticle } = useShoppingCartEvents();
    const handleDeleteArticle = () => {
        setFlaggedArticle(articleIndex);
    };

    const AdditionalServices = useMemo(
        () =>
            additionalServices.map((additionalService, index: number) => (
                <ShoppingCartAdditionalService
                    key={index}
                    parentIndex={articleIndex}
                    additionalService={additionalService}
                    additionalServiceIndex={index}
                />
            )),
        [additionalServices, additionalServices.length],
    );
    return (
        <li className={styles.section}>
            <p className={styles.productName}>
                <strong>{product.name}</strong>
            </p>
            <p className={styles.sectionName}>
                {amount} x {service.name}
            </p>
            <button aria-label={blok.aria_label_delete_article} onClick={handleDeleteArticle} className={`${styles.deleteButton}`} />
            <p className={styles.price}>{formatted.replace('€', '€   ')}</p>
            <>{AdditionalServices}</>
        </li>
    );
};

export default ShoppingCartArticle;
